import { Box, Typography, Card } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Props from "./props";
import QuestionsNavigation from "../../components/QuestionsNavigation";
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../../redux/counterSlice';
import { useState } from "react";
import { updateAnswers } from '../../redux/answersSlice';

function Node6(props: Props) {

    
    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    const dispatch = useDispatch();
    const [a6, setValue] = useState(answers.a6);
    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        console.log("!", newAnswers)
        newAnswers.a6 = a6;
        console.log("!", newAnswers)
        dispatch(updateAnswers(newAnswers))
    }

    let nextQuestion = () => {
        dispatch(increment());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    let previousQuestion = () => {
        if (count === 0) {
            return;
        }
        dispatch(decrement());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };


    return (<Box>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(Last question) What is the best form of communication with you? </Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a6}
                    onChange={handleChange}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Email" />
                    <FormControlLabel value="2" control={<Radio />} label="Telegram" />
                    <FormControlLabel value="3" control={<Radio />} label="WhatsApp" />
                    
                </RadioGroup>
            </FormControl>
        </Card>
        <QuestionsNavigation continue={nextQuestion} back={previousQuestion} />
    </Box>)
}

export default Node6;