import { createSlice } from '@reduxjs/toolkit'

export const answersSlice = createSlice({
  name: 'answers',
  initialState: {
    value: {
        a2_1: undefined,
        a2_2: undefined,
        a2_3: undefined,
        a4: 1,
        a6: "1",
        employees: 1,
        a5: "1"
    }
  },
  reducers: {
    updateAnswers: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateAnswers } = answersSlice.actions;

export default answersSlice.reducer;