import { Box, Typography, Card, Divider, Button } from "@mui/material";
import ResultTable from "../../components/ResultTable";
import Props from "./props";
import { useDispatch } from 'react-redux';
import { increment } from '../../redux/counterSlice';


function Node1(props: Props) {
    const dispatch = useDispatch();

    let nextQuestion = () => {
        dispatch(increment());
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    return (<Box>
        <Typography sx={{mt: "18px"}}>Please answer the folowing 6 questions to receive an estimation of the costs of obtaining a sponsorship licenсe for your company. It will only take 2 minutes.</Typography>
        <Typography sx={{mt: "12px", fontWeight: 600}}>Example of result which you will get in the end.</Typography>
        <Card sx={{backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px"}}>
            <ResultTable rows={
                    [
                        {
                            left: "Certificate of sponsorship costs",
                            right: 239
                        },
                        {
                            left: "Fee for small or charitable sponsor",
                            right: 536
                        },
                        {
                            left: "Fee for immigration skill charge (2 years)",
                            right: 728
                        },
                        {
                            left: "Our fees",
                            right: "2500 + VAT"
                        }
                    ]
                }
            />
            <Divider sx={{my: "8px"}}/>
            <ResultTable rows={
                    [
                        {
                            left: "Total",
                            right: 4962
                        }
                    ]
                }
            />
        </Card>
        <Button sx={{ mx: "auto" }} variant="contained" onClick={nextQuestion}>Start</Button>
  
    </Box>)
}

export default Node1;
