import { Box, Typography, Card, Grid } from "@mui/material";
import ResultTable from "../../components/ResultTable";
import Props from "./props";
import QuestionsNavigation from "../../components/QuestionsNavigation";
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../../redux/counterSlice';
import { useState } from "react";
import { updateAnswers } from '../../redux/answersSlice';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Node5(props: Props) {


    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    console.log("!!!", answers)
    const dispatch = useDispatch();

    let [a5, setValue] = useState(answers.a5);

    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        console.log("!", newAnswers)
        newAnswers.a5 = a5;
        console.log("!", newAnswers)
        dispatch(updateAnswers(newAnswers))
    }

    let nextQuestion = () => {
        dispatch(increment());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    let previousQuestion = () => {
        if (count === 0) {
            return;
        }
        dispatch(decrement());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (<Box>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(5/6) Select service you need.  We have a 2 plans of Sponsor Licence Service:</Typography>

            <FormControl sx={{ width: "100%", mt: "9px" }}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a5}
                    onChange={handleChange}

                >
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6} lg={6} sm={6}>
                            <Card className="planCard" sx={{ p: "9px" }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    Basic
                                </Typography>
                                <Typography sx={{ fontWeight: 200 }}>
                                    - Documents check
                                </Typography>

                            </Card>

                            <FormControlLabel sx={{ mt: "9px" }} value="1" control={<Radio />} label="Use Basic" />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sm={6}>
                            <Card className="planCard" sx={{ p: "9px" }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    Pro
                                </Typography>
                                <Typography sx={{ fontWeight: 200 }}>
                                    - Company assessment<br />
                                    - List of documents<br />
                                    - Application submission<br />
                                </Typography>
                            </Card>
                            <FormControlLabel sx={{ mt: "9px" }} value="2" control={<Radio />} label="Use Pro (recommended)" />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
        </Card>
        <QuestionsNavigation continue={nextQuestion} back={previousQuestion} />
    </Box>)
}

export default Node5;