import { Box, Typography, Card, Checkbox } from "@mui/material";
import Props from "./props";
import QuestionsNavigation from "../../components/QuestionsNavigation";
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../../redux/counterSlice';
import { useState } from "react";
import { updateAnswers } from '../../redux/answersSlice';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Node2(props: Props) {
    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    const dispatch = useDispatch();
    const [errorMsg, showErrorMsg] = useState("");
    let [a2_1, setValue1] = useState(answers.a2_1);
    let [a2_2, setValue2] = useState(answers.a2_2);
    let [a2_3, setValue3] = useState(answers.a2_3);

    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        newAnswers.a2_1 = a2_1;
        newAnswers.a2_2 = a2_2;
        newAnswers.a2_3 = a2_3;
        dispatch(updateAnswers(newAnswers))
    }

    let nextQuestion = () => {
        if (a2_1 === undefined || a2_2 === undefined || a2_3 === undefined) {
            showErrorMsg("Please give all answers.");
            return;
        }
        dispatch(increment());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    let previousQuestion = () => {
        if (count === 0) {
            return;
        }
        dispatch(decrement());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    const handleChangeValue1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue1(event.target.value);
    };

    const handleChangeValue2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue2(event.target.value);
    };

    const handleChangeValue3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue3(event.target.value);
    };

    return (<Box>
        <Typography className="error" sx={{ fontWeight: 600, display: errorMsg ? "flex" : "none" }} marginY={"18px"}>{errorMsg}</Typography>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(1/6) Determine size of your company</Typography>
            <Typography marginBottom={"18px"} sx={{ fontWeight: 200 }}>This will help us to estimate the size of your company, if your values are above the listed below, please continue to the next question</Typography>
            <Typography  marginBottom={"8px"}>1. Your annual turnover?</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a2_1}
                    onChange={handleChangeValue1}
                >
                    <Box>
                        <FormControlLabel value="1" control={<Radio />} label="Fewer or exactly £10.2 million" />
                        <FormControlLabel value="" control={<Radio />} label="More than £10.2 million" />
                    </Box>
                    
                </RadioGroup>
            </FormControl>
            <Typography  marginBottom={"8px"} marginTop={"18px"}>2. Your total assets?</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a2_2}
                    onChange={handleChangeValue2}
                >
                    <Box>
                        <FormControlLabel value="1" control={<Radio />} label="Fewer or exactly £5.1 million" />
                        <FormControlLabel value="" control={<Radio />} label="More than £5.1 million" />
                    </Box>
                    
                </RadioGroup>
            </FormControl>
            <Typography  marginBottom={"8px"} marginTop={"18px"}>3. How many employees?</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a2_3}
                    onChange={handleChangeValue3}
                >
                    <Box>
                        <FormControlLabel value="1" control={<Radio />} label="1-50" />
                        <FormControlLabel value="" control={<Radio />} label="More" />
                    </Box>
                    
                </RadioGroup>
            </FormControl>
        </Card>
        <QuestionsNavigation continue={nextQuestion} back={previousQuestion} />
    </Box>)
}

export default Node2;