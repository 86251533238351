import { Box, Typography, Card, Divider, Button } from "@mui/material";
import Props from "../SponsorLicenseCalculator/components/props";
import { useSelector } from 'react-redux';
import ResultTable from "./ResultTable";


function Result(props: Props) {
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    console.log(answers);

    return (<Box>
        <Typography sx={{ mt: "18px" }}>We have got your request, thank you!</Typography>
        <Typography sx={{ mt: "12px" }}>Our manager will contact you very soon! Find out your Sponsor License fee below.</Typography>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <ResultTable rows={
                [
                    {
                        left: `Certificate of sponsorship costs (£${answers.costOfCOS} * ${answers.employees})`,
                        right: answers.costOfCOSFull
                    },
                    {
                        left: (answers.small || answers.charitable) ? `Worker fee for small or charitable sponsor` : `Worker fee for medium or large sponsor (£${answers.workerFees} * ${answers.employees})`,
                        right: answers.workerFeesFull
                    },
                    {
                        left: `Fee for immigration skill charge (${answers.years}y * £${answers.oneTimePayments} * ${answers.employees})`,
                        right: answers.skillChargeFull
                    },
                    {
                        left: `Our fees: ${answers.ourFeesPlan} Plan`,
                        right: answers.ourFees + " + VAT"
                    }
                ]
            }
            />
            <Divider sx={{ my: "8px" }} />
            <ResultTable rows={
                [
                    {
                        left: "Total",
                        right: answers.skillChargeFull + answers.workerFeesFull + (answers.costOfCOS * answers.employees) + answers.ourFees
                    }
                ]
            } 
            />
        </Card>
        <Box sx={{ mb: "18px" }}>
            <Button sx={{ mx: "auto", mr: "18px", width: { xs: "100%", md: "auto", xl: "auto" }, mb: "18px" }} variant="contained" href="https://calendly.com/sterling-law/sponsor-licence">Book consultation with lawyer</Button>
            <Button sx={{ mx: "auto", mr: "18px", width: { xs: "100%", md: "auto", xl: "auto" }, mb: "18px" }} variant="contained" href="https://calendly.com/kyrylo-sterling/15-minute-consultation?back=1&month=2023-09">FREE 15 min consultation</Button>
            <Button sx={{ mx: "auto", width: "auto", mr: "18px", mb: "18px" }} variant="outlined" href="/sponsor-licence-fees">Calculate again</Button>
            <Button sx={{ mx: "auto", width: "auto", mb: "18px" }} variant="outlined" href="https://sterling-law.co.uk">Back to website</Button>
        </Box>
    </Box>)
}

export default Result;
