import { Box, Typography, Card, Slider, TextField, Button } from "@mui/material";
import Props from "./props";
import QuestionsNavigation from "../../components/QuestionsNavigation";
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../../redux/counterSlice';
import { useState } from "react";
import { updateAnswers } from '../../redux/answersSlice';

function Node4(props: Props) {

    
    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    const dispatch = useDispatch();
    const [a4, setValue] = useState<number | number[]>(answers.a4);
    const [employees, setValueEmployees] = useState<number>(answers.employees);
    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        newAnswers.a4 = a4;
        newAnswers.employees = employees;
        dispatch(updateAnswers(newAnswers))
    }

    let nextQuestion = () => {
        dispatch(increment());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    let previousQuestion = () => {
        if (count === 0) {
            return;
        }
        dispatch(decrement());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }



    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
    };

    const marks = [
        {
            value: 1,
            label: '1 year',
        },
        {
            value: 2,
            label: '2 years',
        },
        {
            value: 3,
            label: '3 years',
        },
        {
            value: 3,
            label: '3 years',
        },
        {
            value: 4,
            label: '4 years',
        },
        {
            value: 5,
            label: '5 years',
        },
    ];


    function valuetext(value: number) {
        return `${value} years`;
    }

    function addEmployee() {
        if (employees === 100) {
            return;
        }
        setValueEmployees(employees + 1);
    }

    function rmEmployee() {
        if (employees === 1) {
            return;
        }
        setValueEmployees(employees - 1);
    }

    return (<Box>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(3/6) Specify the period for which you are hiring the employee: </Typography>
            <Box sx={{p: "18px"}}>
                <Slider
                    aria-label="Custom marks"
                    getAriaValueText={valuetext}
                    value={a4}
                    onChange={handleChange}
                    step={0.5}
                    valueLabelDisplay="auto"
                    marks={marks}
                    max={5}
                    min={1}
                />
            </Box>
        </Card>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(4/6) Please enter how many foreign employees you would like to hire:</Typography>
            <Button className="counterButton" variant="outlined" sx={{display: count === 0 ? "none" : "inline-flex"}} onClick={rmEmployee}>-1</Button>
            <Typography sx={{ fontWeight: 600, display: "inline-flex", mx: "18px" }} marginBottom={"8px"}>Foreign employees: {employees}</Typography>
            <Button className="counterButton" variant="outlined" sx={{display: count === 0 ? "none" : "inline-flex"}} onClick={addEmployee}>+1</Button>
            
        </Card>
        <QuestionsNavigation continue={nextQuestion} back={previousQuestion} />
    </Box>)
}

export default Node4;