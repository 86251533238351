import React from 'react';
import QuestionSequence from './components/QuestionSequence';
import {Container} from '@mui/material';

import { Route, Routes } from "react-router-dom";
import './App.css';

function App() {
  return (
    <Container maxWidth="md">
      <Routes>
        <Route path="/sponsor-licence-fees" element={<QuestionSequence />} />
      </Routes>
    </Container>
  );
}

export default App;
