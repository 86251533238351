import { Box, Grid, Typography } from "@mui/material";


interface Props {
    rows: Array<{
        left: string,
        right: number|string
    }>
}


function ResultTable(props: Props) {
    return (<Box>
        {props.rows.map((item)=>{return (<Grid container paddingY={"8px"}>
            <Grid item xs={12} md={8}>
                <Typography textAlign={"left"}>{item.left}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography textAlign={"right"}>£{item.right}</Typography>
            </Grid>
        </Grid>)})}
        
    </Box>);
}

export default ResultTable;