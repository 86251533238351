import { useState } from "react";
import { Typography, Box } from "@mui/material";
import styles from "./Styles.module.css";
import classNames from 'classnames';
import logo from '../logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { Node1, Node2, Node3, Node4, Node5, Node6 } from "../SponsorLicenseCalculator";
import Form from "./Form";
import Result from "./Result";

function QuestionSequence() {
    console.log(1)
    //@ts-ignore
    const count = useSelector(state => state.counter.value)
    const dispatch = useDispatch();
    const [animate, setAnimate] = useState(false);

    let map: JSX.Element[] = [
        <Node1 setAnimate={setAnimate}/>,
        <Node2 setAnimate={setAnimate}/>,
        <Node3 setAnimate={setAnimate}/>,
        <Node4 setAnimate={setAnimate}/>,
        <Node5 setAnimate={setAnimate}/>,
        // <Node7 setAnimate={setAnimate}/>,
        <Node6 setAnimate={setAnimate}/>,
        <Form setAnimate={setAnimate}/>,
        <Result setAnimate={setAnimate}/>
    ];
 
    return (<Box sx={{ mt: 6, mb: 6 }}>
        <a href="https://sterling-law.co.uk"><img width={"120px"} src={logo} alt="logo" /></a>
        <Typography variant="h1" sx={{ fontWeight: 600, mt: "36px" }}>Sponsor Licenсe Calculator</Typography>
        <Box className={classNames(
            animate && styles.animate,
            animate && styles.grow
        )}>
            {map[count]}
        </Box>
    </Box>
    )
}

export default QuestionSequence;