let consts = {
    API_URL: '/tools_api',
    dict: {}
}


if (process.env.NODE_ENV === "development") {
    consts.API_URL = "http://127.0.0.1:4000"
} 

export default consts;