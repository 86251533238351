import Props from "../SponsorLicenseCalculator/components/props";
import { Box, Typography, Card, TextField, Button } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { increment } from '../redux/counterSlice';
import { updateAnswers } from '../redux/answersSlice';
import { useState } from "react";
import axios from "axios";
import consts from "../consts";


function Form(props: Props) {
    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [errorMsg, showErrorMsg] = useState("");
    const [payloads, setPayloads] = useState<any>({});
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail((event.target as HTMLInputElement).value);
    };
    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone((event.target as HTMLInputElement).value);
    };
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName((event.target as HTMLInputElement).value);
    };
    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        newAnswers.costOfCOS = 239;
        newAnswers.costOfCOSFull = 239 * newAnswers.employees;
        console.log(answers.a2_1, answers.a2_2, answers.a2_3)
        let a2_1 = answers.a2_1 | 0;
        let a2_2 = answers.a2_2 | 0;
        let a2_3 = answers.a2_3 | 0;

        console.log(a2_1, a2_2, a2_3)

        newAnswers.small = (a2_1 + a2_2 + a2_3) > 1;
        newAnswers.charitable = answers.a3 === "1"; // charitable
        newAnswers.years = answers.a4;

        if (newAnswers.small || newAnswers.charitable) {
            newAnswers.workerFees = 536;
            newAnswers.skillCharge = 364 * newAnswers.years;
            newAnswers.oneTimePayments = 364;
        } else {
            newAnswers.workerFees = 1476;
            newAnswers.skillCharge = 1000 * newAnswers.years;
            newAnswers.oneTimePayments = 1000;
        }
        newAnswers.workerFeesFull = newAnswers.workerFees;
        newAnswers.skillChargeFull = newAnswers.skillCharge * newAnswers.employees;
        newAnswers.ourFees = answers.a5 === "1" ? 900 : 2500;
        newAnswers.ourFeesPlan = answers.a5 === "1" ? "Basic" : "Pro";
        
        if (answers.a6 === "1")
            newAnswers.contactVia = "email";
        
        if (answers.a6 === "2")
            newAnswers.contactVia = "telegram";
        
        if (answers.a6 === "3")
            newAnswers.contactVia = "whatsapp";

        newAnswers.tableBody = [
            {
                left: `Certificate of sponsorship costs (£${newAnswers.costOfCOS} * ${newAnswers.employees})`,
                right: newAnswers.costOfCOSFull
            },
            {
                left: (newAnswers.small || newAnswers.charitable) ? `Worker fee for small or charitable sponsor` : `Worker fee for medium or large sponsor (£${newAnswers.workerFees} * ${newAnswers.employees})`,
                right: newAnswers.workerFeesFull
            },
            {
                left: `Fee for immigration skill charge (${newAnswers.years}y * £${newAnswers.oneTimePayments} * ${newAnswers.employees})`,
                right: newAnswers.skillChargeFull
            },
            {
                left: `Our fees: ${newAnswers.ourFeesPlan} Plan`,
                right: newAnswers.ourFees + " + VAT"
            }
        ]

        newAnswers.tableResult = [
            {
                left: "Total",
                right: newAnswers.skillChargeFull + newAnswers.workerFeesFull + (newAnswers.costOfCOS * newAnswers.employees) + newAnswers.ourFees
            }
        ];

        dispatch(updateAnswers(newAnswers))
        return newAnswers;
    }


    let nextQuestion = () => {

        let submition = onSubmit(updateAnswersAtStore());
        if (!submition) {
            return;
        }

        dispatch(increment());
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    function emailValidation() {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email) {
            showErrorMsg("Please type your email address.")
            return false;
        }
        if (regex.test(email) === false) {
            showErrorMsg("Email is not valid.")
            return false;
        }
        if (!phone) {
            showErrorMsg("Please type your phone number.")
            return false;
        }
        return true;
    }

    function onSubmit(answers_throw: any) {
        if (!name) {
            showErrorMsg("Please type your name.")
            return false;
        }

        if (emailValidation()) {
            axios.post(`${consts.API_URL}/sponsor-licence-fees`, {
                email: email,
                name: name,
                phone: phone,
                payloads: {
                    small: answers_throw.small,
                    charitable: answers_throw.charitable,
                    years: answers_throw.years,
                    workerFees: answers_throw.workerFees,
                    skillCharge: answers_throw.skillCharge,
                    oneTimePayments: answers_throw.oneTimePayments,
                    ourFees: answers_throw.ourFees,
                    employees: answers_throw.employees,
                    skillChargeFull: answers_throw.skillChargeFull,
                    workerFeesFull: answers_throw.workerFeesFull,
                    contactVia: answers_throw.contactVia,
                    tableBody: answers_throw.tableBody,
                    tableResult: answers_throw.tableResult
                }
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(r => { console.log("ok") });
            return true;
        } else {
            return false;
        }
    }

    return <div>
        <Typography sx={{ mt: "18px" }}>We are almost done! Fill form and get your result.</Typography>
        <Typography className="error" sx={{ fontWeight: 600, display: errorMsg ? "flex" : "none" }} marginY={"18px"}>{errorMsg}</Typography>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: { sm: "40px", md: "200px" } }}>
            <TextField
                onChange={handleChangeName}
                InputProps={{
                    sx: {
                        "& input": {
                            textAlign: "center",
                            outlineWidth: 0,
                            outline: "none"
                        }
                    }
                }}
                sx={{
                    mb: "18px"
                }}
                value={name}
                fullWidth
                id="name"
                placeholder="Your Name"
            />
            <TextField
                onChange={handleChangeEmail}
                InputProps={{
                    sx: {
                        "& input": {
                            textAlign: "center",
                            outlineWidth: 0,
                            outline: "none"
                        }
                    }
                }}
                sx={{
                    mb: "18px"
                }}
                id="email"
                value={email}
                fullWidth
                placeholder="Email"
            />
            <TextField
                onChange={handleChangePhone}
                InputProps={{
                    sx: {
                        "& input": {
                            textAlign: "center",
                            outlineWidth: 0,
                            outline: "none"
                        }
                    }
                }}

                id="phone"
                fullWidth
                value={phone}
                placeholder="Phone"
            />
        </Card>
        <Box sx={{ display: "flex" }}>
            <Button sx={{ mx: "auto" }} variant="contained" onClick={nextQuestion}>Calculate</Button>
        </Box>
    </div>
}

export default Form;
