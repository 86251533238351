import { Box, Button } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux';

interface Props {
    continue: () => void;
    back: () => void;
}

export default function QuestionsNavigation(props: Props) {
    //@ts-ignore
    const count = useSelector(state => state.counter.value)
    return (<Box>
        <Button variant="outlined" sx={{mr: "9px", display: count === 0 ? "none" : "inline-flex"}} onClick={props.back}>Back</Button>
        <Button variant="contained" onClick={props.continue}>Continue</Button>
    </Box>)
}