import { Box, Typography, Card } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Props from "./props";
import QuestionsNavigation from "../../components/QuestionsNavigation";
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../../redux/counterSlice';
import { useState } from "react";
import { updateAnswers } from '../../redux/answersSlice';

function Node3(props: Props) {
    //@ts-ignore
    const count = useSelector(state => state.counter.value);
    //@ts-ignore
    const answers = useSelector(state => state.answers.value);
    const dispatch = useDispatch();
    const [a3, setValue] = useState(answers.a3);
    const [errorMsg, showErrorMsg] = useState("");
    let updateAnswersAtStore = () => {
        let newAnswers = Object.assign({}, answers);
        console.log("!", newAnswers)
        newAnswers.a3 = a3;
        console.log("!", newAnswers)
        dispatch(updateAnswers(newAnswers))
    }

    let nextQuestion = () => {
        if (a3 === undefined) {
            showErrorMsg("This is a required question, indicate 'none' if you do not know the answer.");
            return;
        }
        showErrorMsg("");
        dispatch(increment());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }

    let previousQuestion = () => {
        if (count === 0) {
            return;
        }
        dispatch(decrement());
        updateAnswersAtStore();
        props.setAnimate(true);
        setTimeout(() => props.setAnimate(false), 1000)
    }



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };


    return (<Box>
        <Typography className="error" sx={{ fontWeight: 600, display: errorMsg ? "flex" : "none" }} marginY={"18px"}>{errorMsg}</Typography>
        <Card sx={{ backgroundColor: "#eeeeee", my: "24px", py: "30px", px: "40px" }}>
            <Typography sx={{ fontWeight: 600 }} marginBottom={"8px"}>(2/6) Are you a charitable sponsor?</Typography>
            <Typography marginBottom={"18px"} sx={{ fontWeight: 200 }}>You are a charitable sponsor if you are satisfy at least one option:<br/>
            ·   a registered charity in England or Wales;<br/>
            ·   a registered charity in Scotland;<br/>
            ·   a registered charity in Northern Ireland - if you’re not on the register, you must provide proof of your charitable status for tax purposes from HM Revenue and Customs (HMRC);<br/>
            ·   an excepted charity;<br/>
            ·   an exempt charity;<br/>
            ·   an ecclesiastical corporation established for charitable purposes.</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={a3}
                    onChange={handleChange}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
        </Card>

        <QuestionsNavigation continue={nextQuestion} back={previousQuestion} />
    </Box>)
}

export default Node3;